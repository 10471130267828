<template>
  <div class="main">
    <Modal
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
      width="40%"
    >
      <div class="title-container d-flex justify-center">
        <h2 class="modal-title me-3" style="font-weight: bolder">
          Detail User Aktif
        </h2>
        <div class="info-icon-wrapper">
          <InfoIcon class="info-icon align-items-center" />
          <div class="tooltip">Detail informasi data pembelian user</div>
        </div>
      </div>

      <br />

      <div class="modal-body" v-if="selectedUser">
        <div class="data-list">
          <div
            class="detail-data-1"
            style="
              width: 233px;
              border: 1px solid #c3c3c6;
              text-align: left;
              border-radius: 10px;
            "
          >
            <p class="detail-label">No.Invoice</p>
            <p class="detail-value">{{ selectedUser.invoice_number }}</p>
          </div>
          <div
            class="detail-data-2"
            style="
              width: 500px;
              border: 1px solid #c3c3c6;
              text-align: left;
              border-radius: 10px;
            "
          >
            <p class="detail-label">Waktu Pembelian</p>
            <p class="detail-value">{{ selectedUser.payment_time }}</p>
          </div>
        </div>
        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">Nama Lengkap</p>
            <p class="detail-value">
              {{ selectedUser.name }}
            </p>
          </div>
        </div>
        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">No.Handphone</p>
            <p class="detail-value">(+62) {{ selectedUser.phone_number }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Email</p>
            <p class="detail-value">{{ selectedUser.email }}</p>
          </div>
        </div>

        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">Voucher</p>
            <p
              class="detail-value"
              v-html="getVouchers(selectedUser.voucher)"
            ></p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Nominal Transaksi</p>
            <p class="detail-value">
              IDR. {{ formatPrice(selectedUser.no_transaction[0]) }}
              <span
                v-for="(discount, index) in selectedUser.no_transaction[1]
                  .discounts"
                :key="index"
              >
                - IDR. {{ formatPrice(discount) }}
              </span>
              =
              <span style="color: #b80f0a">
                IDR.
                {{
                  formatPrice(calculateTotalPrice(selectedUser.no_transaction))
                }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </Modal>

    <div class="breadcrumb-download">
      <v-breadcrumbs :items="breadcrumbsItems">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <div class="card-container">
      <div class="filter">
        <v-select
          v-model="programValue"
          :items="selectProgram"
          hide-details
          label="Pilih Program"
          chips
          multiple
          solo
        ></v-select>

        <v-select :items="selectBatch" label="Pilih Batch" solo></v-select>
        <Button name="TERAPKAN" />
      </div>

      <div class="card-grid">
        <v-row>
          <v-col
            style="width: 300px"
            md="4"
            v-for="item in bootcamps"
            :key="item.title"
          >
            <div class="card-program">
              <div class="program-content">
                <div class="header-program d-flex">
                  <div class="program-title">
                    <div class="title-program">
                      {{ item.title }}
                    </div>
                    <div class="batch-program">
                      {{ item.batch }}
                    </div>
                  </div>
                  <div
                    class="arrow-detail"
                    @click="
                      () =>
                        $router.push(`/transaction/b2b/bootcamp/` + item.title)
                    "
                    style="cursor: pointer"
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.7071 11.7931C15.8946 11.9807 15.9999 12.235 15.9999 12.5001C15.9999 12.7653 15.8946 13.0196 15.7071 13.2071L10.0501 18.8641C9.95785 18.9597 9.84751 19.0358 9.7255 19.0882C9.6035 19.1407 9.47228 19.1682 9.3395 19.1694C9.20672 19.1705 9.07504 19.1452 8.95215 19.095C8.82925 19.0447 8.7176 18.9704 8.6237 18.8765C8.52981 18.7826 8.45556 18.671 8.40528 18.5481C8.355 18.4252 8.32969 18.2935 8.33085 18.1607C8.332 18.028 8.35959 17.8967 8.412 17.7747C8.46441 17.6527 8.54059 17.5424 8.6361 17.4501L13.5861 12.5001L8.6361 7.55014C8.45394 7.36154 8.35315 7.10894 8.35542 6.84674C8.3577 6.58455 8.46287 6.33373 8.64828 6.14833C8.83369 5.96292 9.0845 5.85775 9.3467 5.85547C9.60889 5.85319 9.8615 5.95399 10.0501 6.13614L15.7071 11.7931Z"
                        fill="#ACAFBF"
                      />
                    </svg>
                  </div>
                </div>

                <div class="sessions-participants-revenue">
                  <div class="sessions">
                    <h2>{{ item.sessions }}</h2>
                    <p>Jumlah Sesi</p>
                  </div>

                  <div class="participants">
                    <h2>{{ item.participants }}</h2>
                    <p>Peserta Terdaftar</p>
                  </div>

                  <div class="revenue">
                    <h2>{{ item.totalRevenue }}</h2>
                    <p>Total Revenue</p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row class="mt-4" v-if="totalData" style="color: #515151">
        Menampilkan
        {{ Math.floor((currentPage - 1) * 10) }} dari
        {{ totalData }}
        data
      </v-row>
      <v-row justify="center" v-if="total">
        <v-col cols="4">
          <v-container class="max-width">
            <v-pagination
              v-model="currentPage"
              class="my-4"
              :length="total"
              @input="getUsers"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
    
    
  <script>
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
export default {
  name: "History",
  components: {
    Button,
    Modal,
    InfoIcon,
  },
  data: () => ({
    currentPage: 10,
    total: 2,
    totalData: 20,
    bootcamps: [
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
      {
        title: "Project Management",
        batch: 6,
        sessions: 12,
        participants: "120+",
        totalRevenue: "16.7K",
      },
    ],
    selectProgram: ["UI/UX", "Fullstack", "Design Graphics"],
    selectBatch: ["Batch 1", "Batch 2", "Batch 3"],
    programValue: ["UI/UX", "Fullstack", "Design Graphics"],

    breadcrumbsItems: [
      {
        text: "Finance",
        disabled: false,
        href: "/home",
      },
      {
        text: "(B2C) Bootcamp",
        disabled: false,
        href: "/transaction/b2b/bootcamp/",
      },
      {
        text: "Riwayat",
        disabled: true,
        href: "",
      },
    ],
    isModalDetail: false,
  }),
  methods: {
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getDiscounts(discounts) {
      return discounts
        .map((discount) => `IDR. ${this.formatPrice(discount)}`)
        .join("<br /> ");
    },
    getVouchers(vouchers) {
      return vouchers.map((voucher) => `${voucher}`).join("<br />");
    },
    editUser(item) {
      this.$router.push("/transaction/b2b/bootcamp/edit/" + item.name);
    },
    viewUser(user) {
      this.selectedUser = user;
      this.isModalDetail = true;
    },
    calculateTotalPrice(no_transaction) {
      const normalPrice = no_transaction[0];
      const discounts = no_transaction[1].discounts;
      const totalDiscount = discounts.reduce((acc, curr) => acc + curr, 0);
      return normalPrice - totalDiscount;
    },
  },
};
</script>
  
    
    <style scoped>
.main {
  margin: 50px 40px;
}

.breadcrumb-download {
  display: flex;
  justify-content: space-between;
}

.btn-download {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #d3d6e1;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #7b7e8c;
  width: 156px;
  height: 44px;
  padding: 6px 30px 6px 30px;
}
.btn-actions {
  display: flex;
  gap: 15px;
}
.btn-watch {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #2d46cf80;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #2d46cf;
  width: 79px;
  height: 38px;
  padding: 8px 20px 8px 20px;
}
.btn-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #d3d6e1;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #7b7e8c;
  width: 79px;
  height: 38px;
  padding: 8px 20px 8px 20px;
}
::v-deep .v-breadcrumbs__item {
  color: #162ca2 !important;
  font-size: 14px !important;
  font-weight: 600;
}

::v-deep .v-breadcrumbs__item--disabled {
  color: #7b7e8c !important;
}

.card-container {
  margin: 34px 15px;
  width: 100%;
  height: auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 20px;
}

.table-wrapper {
  overflow-x: auto;
}

.filter {
  display: flex;
  gap: 18px;
  width: fit-content;
  justify-content: flex-start;
}

.transact-item {
  display: flex;
  gap: 30px;
}

.transact-item p {
  width: 100px;
}

.discount-item {
  display: flex;
  flex-direction: column;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.info-icon-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.info-icon-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.info-icon {
  cursor: pointer;
}

.card-program {
  width: 300px;
  height: 170px;
  border: 1px solid #d3d6e1;
  margin-top: 15px;
  border-radius: 10px;
  padding: 15px 25px;
}

.card-program:hover {
  width: 300px;
  height: 170px;
  border: 1px solid #2d46cf;
  margin-top: 15px;
  border-radius: 10px;
  padding: 15px 25px;
}

.program-title {
  font-size: 16px;
  font-weight: 600;
}

.title-program {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 219px;
}

.batch-program {
  font-size: 12px;
  font-weight: 500;
}

.sessions-participants-revenue {
  width: 214px;
  height: 73px;
  background-color: #f1f6ff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 15px;
  display: flex;
  gap: 20px;
}

.sessions,
.participants,
.revenue {
  width: 50px;
}

.sessions-participants-revenue h2 {
  font-size: 16px;
  font-weight: 600;
  color: #2d46cf;
}

.sessions-participants-revenue .revenue h2 {
  font-size: 16px;
  font-weight: 600;
  color: #0ea976;
}

.sessions-participants-revenue p {
  font-size: 10px;
  font-weight: 500;
  color: #7b7e8c;
}
</style>
    